import React, { lazy, StrictMode, Suspense } from "react";
import {
  ColorModeScript,
  ChakraProvider,
  theme,
  useColorMode,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

const App = lazy(() => import("./App"));

const Loading = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      p={8}
      direction={{ base: "column-reverse", lg: "row" }}
      justify="center"
      align="center"
      zIndex={2}
    >
      <Flex direction="column">
        <Spinner size="xl" />
        <Text color="white">Loading...</Text>
      </Flex>
    </Flex>
  );
};

const AppLoader = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  if (colorMode === "light") toggleColorMode();
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
};

ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      {" "}
      <AppLoader />
    </ChakraProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
